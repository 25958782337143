import React, { useRef, useEffect, useState } from 'react';
import styles from './Page.module.scss';

type Props = {
  isIndex?: boolean,
  title?: string,
  children: React.Node
};

const Page = ({ title, children, isIndex }: Props) => {
  const pageRef = useRef();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (!isIndex && !scrolled) {
      pageRef.current.scrollIntoView();
      setScrolled(true);
    }
  }, [isIndex]);

  return (
    <div ref={pageRef} className={styles['page']}>
      <div className={styles['page__inner']}>
        { title && <h1 className={styles['page__title']}>{title}</h1>}
        <div className={styles['page__body']}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Page;
